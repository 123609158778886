<template>
    <div>
        <sidebar></sidebar>
        <div class="main-content">
            <div class="links-page">
                <div class="row no-gutters">
                    <div class="col-lg-7 col-12">
                        <router-view class="fullHeight" />
                    </div>

                    <div class="col-5 d-lg-block d-none" style="border-left: 1px solid #e3ebf6">
                        <preview></preview>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Preview from "@/components/Preview";
import { mapActions } from "vuex";

export default {
    components: {
        Sidebar,
        Preview
    },
    mounted() { },
    data() {
        return {
            loaded: false,
            company: {},
        }
    },
    computed: {
       
    },
    created() {

    },

    methods: {
        
    }
};
</script>

<style lang="scss" scoped>

</style>